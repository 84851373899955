
.profile {
  .media-left {
    width: 140px;
    // height: 140px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      //   height: 100%;
      border-radius: 20px !important;
    }
  }
}
